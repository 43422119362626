.background {
  text-align: center;
  background-color: #004C5F;
  background-position: center;
  min-height: 100vh;
  padding-top: 5%;
  padding-bottom: 5%;
  background-image: url(./marble1.jpg);
}
.header {
  margin-left: 10%;
  margin-right: 10%;
  position : center;
  top : 6px;
  align-items: center;
  font-size: calc(18px + 2vmin);
  color: black;
  font-family: monospace;
  text-decoration: underline;
  }

  .text {
    font-size: calc(10px + 2vmin);
    color: black;
    font-family: monospace;
  }

  .border {
      border-style: solid;
      border: 2;
      color: black;
    }

  .topnav {
  overflow: hidden;
  background-color: #333;

}

/* Navbar links */
.topnav a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 8px 16px;
  text-decoration: none;
  font-family: monospace;
  font-size: calc(10px + 2vmin);

}

/* Links - change color on hover */
.topnav a:hover {
  background-color: #ddd;
  color: black;
}

.column {
  float: left;
  padding: 10px;
}

/* Left and right column */
.column.side {
  width: 40%;
}

/* Middle column */
.column.middle {
  width: 50%;
}

.button {
  flex-direction: row;
  padding-top: 2%;
}

.button a{
  margin-left: 5%;
  margin-right: 5%;
  width: 25%;
  border-radius: 25px;
  size: large;
}
